<template>
  <div>
    <!-- form starts -->
    <vue-element-loading
      :active="isSubmitted"
      text="MYLE"
      :text-style="loadStyle"
      :is-full-screen="true"
      class="h-full background-overlay"
      spinner="bar-fade-scale"
      color="#ffffff"
    />

    <stripe-checkout
      ref="checkoutRef"
      mode="payment"
      :pk="checkout.publishKey"
      :sessionId="checkout.clientReferenceId"
    />

    <!-- conifrm model -->
    <nu-modal ref="confirmmodal" title="">
      <confirmationPopup :showsuccess="showsuccess" @closemodal="closeModal" />

      <!--  <div class="container-fluid">
          <div class="row">
            <div class="col-md-12">
              <div class="text-center">
                <h3 class="w-auto d-inline-block">
                  {{
                    $route.query.checkout == "success"
                      ? "Successful Purchased"
                      : "Oops!"
                  }}
                </h3>
              </div>
            </div>
  
            <div class="col-md-12">
              <div class="text-center">
                {{
                  $route.query.checkout == "success"
                    ? "You have successfully purchased the tickets. Please check your MYLE account email inbox for confirmation email with attached ticket file. You can also check your purchased history in account tab here and in MYLE mobile app as well."
                    : "Something went wrong with the payment. Please try again."
                }}
              </div>
            </div>
  
            <div class="col-md-12">
              <div class="text-center">
                <br />
                <button
                  type="button"
                  @click="$refs.confirmmodal.close()"
                  class="btn solid-btn-uncf my-auto"
                  style="
                    pointer-events: all;
                    cursor: pointer;
                    padding: 7px;
                    width: 150px;
                    margin-top: 11px !important;
                  "
                >
                  Ok
                </button>
              </div>
            </div>
          </div>
        </div>-->
    </nu-modal>
    <!-- confrim model -->

    <nu-modal ref="modal" title="">
      <form>
        <div class="container-fluid">
          <div class="row">
            <div class="col-md-12">
              <div class="text-center">
                <h3 class="w-auto d-inline-block">Ticket Purchase Form</h3>
              </div>
            </div>
            <div class="col-md-12 mt-4">
              <p>
                <b class="font-weight-bold">Ticket Title:</b>
                {{
                  TicketsResult[selected]
                    ? TicketsResult[selected].ticketName
                    : ""
                }}
              </p>
              <p
                v-if="
                  !redeemData &&
                  TicketsResult[selected] &&
                  TicketsResult[selected].ticketType != 'FREE'
                "
              >
                <b class="font-weight-bold">Per Person Price:</b> ${{
                  TicketsResult[selected]
                    ? TicketsResult[selected].price.toFixed(2)
                    : ""
                }}
              </p>
              <p v-if="redeemData && redeemData.price">
                <b class="font-weight-bold">Per Person Price:</b> ${{
                  redeemData.price.toFixed(2)
                }}
              </p>
              <p
                v-if="
                  !redeemData &&
                  TicketsResult[selected] &&
                  TicketsResult[selected].ticketType != 'FREE'
                "
              >
                <b class="font-weight-bold">Total Price:</b> ${{
                  (
                    (TicketsResult[selected]
                      ? TicketsResult[selected].price
                      : 0) * tickcount
                  ).toFixed(2)
                }}
              </p>
              <p v-if="redeemData && redeemData.price">
                <b class="font-weight-bold">Total Price:</b> ${{
                  ((redeemData.price || 0) * tickcount).toFixed(2)
                }}
              </p>
            </div>
            <div class="col-md-6">
              <input
                type="text"
                class="form-control mb-3"
                placeholder="Purchaser Full Name"
                required
                v-model="fullname"
              />
            </div>
            <div class="col-md-6">
              <input
                type="email"
                class="form-control mb-3"
                placeholder="Email"
                v-model="email"
                required
              />
            </div>
            <div class="col-md-6 mb-3">
              <label class="mt-3">{{
                [...section2].includes(
                  (TicketsResult[selected] || {}).ticketCode
                )
                  ? "Number of Sponsorship(s)"
                  : "Number of Ticket(s)"
              }}</label>
              <select
                class="form-control"
                v-model="tickcount"
                @change="countChange()"
              >
                <option
                  class="form-control"
                  v-bind:key="count + 'b'"
                  v-for="count in tickcountlist"
                  :value="count"
                >
                  {{ count }}
                </option>
              </select>
            </div>

            <div
              class="col-md-6 mt-3"
              v-if="TicketsResult[selected] && TicketsResult[selected].discount"
            >
              <label class="">Do you have a promo code?</label>

              <input
                type="text"
                v-model="redeempromo"
                class="form-control"
                placeholder="Promo code"
              />
            </div>

            <div
              class="col-md-6"
              v-if="TicketsResult[selected] && TicketsResult[selected].discount"
            ></div>

            <div
              class="col-md-6 text-center"
              id="redeem"
              v-if="TicketsResult[selected] && TicketsResult[selected].discount"
            >
              <button
                type="button"
                @click="redeem()"
                :disabled="!redeempromo.trim().length"
                class="btn solid-btn-uncf3rd btn-sm my-auto"
                style="
                  pointer-events: all;
                  cursor: pointer;
                  padding: 7px;
                  margin-top: 11px !important;
                  font-family: 'nunito', sans-serif;
                  font-weight: 700;
                  font-size: 1.2em;
                  font-weight: bold;
                "
              >
                Redeem Code
              </button>
            </div>

            <!--
                            <div
                              class="row col-md-12 mt-4"
                              v-if="TicketsResult[selected] && TicketsResult[selected].discount"
                            >
                              <div class="col-md-6">
                                <label class="">Do you have a promo code?</label>
  
                                <input
                                  type="text"
                                  v-model="redeempromo"
                                  class="form-control"
                                  placeholder="Promo code"
                                />
                              </div>
  
                              <div class="col-md-4">
                                <button
                                  type="button"
                                  @click="redeem()"
                                  :disabled="!redeempromo.trim().length"
                                  class="btn solid-btn btn-sm my-auto"

                                  style="
                                    pointer-events: all;
                                    cursor: pointer;
                                    padding: 7px;
                                    margin-top: 11px !important;
                                  "
                                >
                                  Redeem
                                </button>
                              </div>
                            </div> -->

            <div
              class="row col-md-12 m-auto mt-4 w-100 p-0 pb-4"
              v-if="
                false && !section2.includes(TicketsResult[selected].ticketCode)
              "
            >
              <br />
              <hr class="w-100 p-2" />
              <br />

              <div class="col-md-6">
                <input
                  type="text"
                  v-model="efName"
                  class="form-control"
                  placeholder="Primary Contact First Name"
                />
              </div>
              <div class="col-md-6">
                <input
                  type="text"
                  v-model="elName"
                  class="form-control"
                  placeholder="Last Name"
                />
              </div>
            </div>
            <div
              class="row col-md-12 m-auto mt-4 w-100 p-0 pb-4"
              v-if="
                false &&
                [...section2].includes(TicketsResult[selected].ticketCode)
              "
            >
              <div class="col-md-6">
                <input
                  type="text"
                  v-model="eEmail"
                  class="form-control"
                  placeholder="Email Address"
                />
              </div>
              <div class="col-md-6">
                <input
                  type="text"
                  v-model="emNumber"
                  class="form-control"
                  placeholder="Mobile Number"
                />
              </div>
            </div>

            <div
              class="row col-md-12 m-auto mt-4 w-100 p-0 pb-4"
              v-if="
                false &&
                [...section2].includes(TicketsResult[selected].ticketCode)
              "
            >
              <div class="col-md-6">
                <input
                  type="text"
                  v-model="eTitle"
                  class="form-control"
                  placeholder="Title"
                />
              </div>
              <div class="col-md-6">
                <input
                  type="text"
                  v-model="eRefferedBy"
                  class="form-control"
                  placeholder="Referred By: (if applicable)"
                />
              </div>
            </div>

            <div
              class="row col-md-12 m-auto mt-4 w-100 p-0 pb-4"
              v-if="
                false &&
                [...section2].includes(TicketsResult[selected].ticketCode)
              "
            >
              <div class="col-md-6">
                <input
                  type="text"
                  v-model="business_organization_name"
                  class="form-control"
                  placeholder="Business / Organization"
                />
              </div>
            </div>

            <div
              class="row col-md-12 m-auto mt-4 w-100 p-0 pb-4"
              v-if="
                false && section1.includes(TicketsResult[selected].ticketCode)
              "
            >
              <br />
              <hr class="w-100 p-2" />
              <br />

              <div class="col-md-6">
                <input
                  type="text"
                  v-model="efatherName"
                  class="form-control"
                  placeholder="Father/Father Figure First Name"
                />
              </div>
              <div class="col-md-6">
                <input
                  type="text"
                  v-model="efEmail"
                  class="form-control"
                  placeholder="Last Name"
                />
              </div>
            </div>
            <div
              class="row col-md-12 m-auto mt-4 w-100 p-0 pb-4"
              v-if="
                false && section1.includes(TicketsResult[selected].ticketCode)
              "
            >
              <div class="col-md-6">
                <input
                  type="text"
                  v-model="ePhoneNumber"
                  class="form-control"
                  placeholder="Email Address"
                />
              </div>
              <div class="col-md-6">
                <input
                  type="text"
                  v-model="eOrgNumber"
                  class="form-control"
                  placeholder="Organization Or Institution: ex: DEPA (if applicable)"
                />
              </div>
            </div>
            <div
              class="row col-md-12 m-auto mt-4 w-100 p-0 pb-4"
              v-if="
                false && section1.includes(TicketsResult[selected].ticketCode)
              "
            >
              <div
                class="col-md-6 pb-4"
                v-bind:key="'x2xx1' + indval"
                v-for="indval in agecount[TicketsResult[selected].ticketCode]"
              >
                <input
                  type="number"
                  min="1"
                  max="50"
                  v-model="age['age' + indval]"
                  class="form-control"
                  :placeholder="
                    agecount[TicketsResult[selected].ticketCode].length == 1
                      ? 'Child Age'
                      : agePlaceholders[indval - 1]
                  "
                  @keyup="vchange = !vchange"
                />
              </div>
            </div>

            <hr class="w-100 p-2" v-if="tickcount > 1" />
            <div
              class="col-md-6 pb-4"
              v-bind:key="'name' + indval"
              v-for="indval in totalCount(names)"
            >
              <input
                type="text"
                v-bind:key="'name12' + indval"
                v-model="names['name' + indval]"
                class="form-control"
                placeholder="Additional Attendee Name"
                @keyup="vchange = !vchange"
              />
            </div>

            <hr class="w-100 p-2" />
            <div class="row col-md-12 m-auto mt-4 w-100 p-0 pb-4">
              <div class="col-md-6 mb-2">
                <label class="mt-2">Primary Contact Name *</label>
                <input
                  type="text"
                  v-model="nContactName"
                  class="form-control"
                  placeholder="Primary Contact Name"
                />
              </div>
              <div class="col-md-6 mb-2">
                <label class="mt-2">Email Address *</label>
                <input
                  type="text"
                  v-model="nEmailAddress"
                  class="form-control"
                  placeholder="Email Address"
                />
              </div>

              <div class="col-md-6 mb-2">
                <label class="mt-2">Street Address *</label>
                <input
                  type="text"
                  v-model="nStreetAddress"
                  class="form-control"
                  placeholder="Street Address"
                />
              </div>
              <div class="col-md-6 mb-2">
                <label class="mt-2">City *</label>
                <input
                  type="text"
                  v-model="nCity"
                  class="form-control"
                  placeholder="City"
                />
              </div>
              <div class="col-md-6 mb-2">
                <label class="mt-2">Select State *</label>
                <select
                  placeholder="State"
                  class="form-control"
                  v-model="nState"
                >
                  <option
                    v-for="s in states"
                    v-bind:key="s"
                    :value="s['States in USA']"
                  >
                    {{ s["States in USA"] }}
                  </option>
                </select>
              </div>
              <div class="col-md-6 mb-2">
                <label class="mt-2">Zip Code *</label>
                <input
                  type="text"
                  v-model="nZip"
                  class="form-control"
                  placeholder="Zip Code"
                />
              </div>
              <div class="col-md-6 mb-2">
                <label class="mt-2">Select School District *</label>
                <select
                  placeholder="District"
                  class="form-control"
                  v-model="nDistrict"
                >
                  <option
                    v-for="s in district"
                    v-bind:key="s"
                    :value="s['Dr']['superintendent']"
                  >
                    {{ s["Dr"]["superintendent"] }}
                  </option>
                </select>
              </div>
              <div class="col-md-6 mb-2"></div>
            </div>

            <div class="col-md-12 mt-4">
              <input type="checkbox" v-model="reserveFormAccept" />
              I understand that all sales are final. No refunds. I have read and
              agree with the MYLE
              <a
                href="https://myle.com/terms"
                target="_blank"
                rel="noopener noreferrer"
              >
                terms and conditions.</a
              >
            </div>

            <div class="col-md-12 text-center mt-4">
              <button
                type="submit"
                @click="paynow()"
                :disabled="
                  !fullname.trim().length ||
                  !invalidemail ||
                  isSubmitted ||
                  !tickcountlist.length ||
                  !reserveFormAccept ||
                  !nContactName.trim().length ||
                  !nStreetAddress.trim().length ||
                  !nCity.trim().length ||
                  !nState.trim().length ||
                  !nZip.trim().length ||
                  !nDistrict.trim().length ||
                  !nEmailAddress.trim().length ||
                  !checkInvalidEmail(nEmailAddress) ||
                  !isvalidName()
                "
                class="btn solid-btn-uncf3rd m-auto mr-2"
                style="
                  pointer-events: all;
                  cursor: pointer;
                  font-family: 'nunito', sans-serif;
                  font-weight: 700;
                  font-size: 1.2em;
                  font-weight: bold;
                "
              >
                Buy now
              </button>
            </div>
          </div>
        </div>
      </form>
    </nu-modal>
    <!-- form ends -->

    <!-- zelle model -->
    <nu-modal ref="zellemodal" title="">
      <div class="container-fluid">
        <div class="row">
          <div class="col-md-12 text-center">
            <img
              style="width: 80%; max-width: 80%"
              src="https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/mylesite/zelle.jpeg"
            />
          </div>
        </div>
      </div>
    </nu-modal>
    <!-- zelle model -->

    <div class="container-fluid p-0" style="overflow-x: hidden">
      <!-- Navbar section starts -->
      <div class="row">
        <div class="col-md-12">
          <nav-bar />
        </div>
      </div>
      <!-- Navbar section ends -->

      <!-- banner section starts -->
      <div class="row text-center">
        <div class="col-md-12" id="banner">
          <img
            class="align-middle"
            src="https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/mylesite/header_InvestinginourFutureScholarshipGala2025.jpg"
            style="
              width: 100%;
              max-width: 100%;
              height: auto;
              margin-top: 4.6rem;
            "
          />
        </div>
      </div>
      <!-- banner section ends -->
      <div class="row w-100 m-0 promo-section MYLE-lite-green">
        <div class="col-lg-12 col-md-12" style="background-color: #2b292a">
          <div class="mt-4">
            <div class="section-heading mb-4">
              <h1
                class="text-center text-white"
                style="
                  font-family: 'nunito', sans-serif;
                  font-weight: 700;
                  font-size: 3em;
                  color: #df0900;
                "
              >
                “Investing in our Future" 3rd Annual Scholarship Gala
              </h1>

              <h1
                class="text-center text-white"
                style="
                  font-family: 'nunito', sans-serif;
                  font-weight: 600;
                  font-size: 1.3em;
                  color: #df0900;
                "
              >
                February 21th, 2025 <br />
                06:00pm - 11pm
              </h1>
              <h1
                class="text-center text-white"
                style="
                  font-family: 'nunito', sans-serif;
                  font-weight: 600;
                  font-size: 1.3em;
                "
              >
                <a
                  href="https://maps.app.goo.gl/NpfJmp1EkRgXLWw9A"
                  target="_blank"
                  rel="noopener noreferrer"
                  style="color: white"
                >
                  Tinley Park Convention Center
                </a>
              </h1>
            </div>
          </div>
        </div>
      </div>
      <!-- Description section starts -->
      <div
        class="row align-items-center justify-content-between test"
        style="margin: auto; margin-top: 20px"
      >
        <div class="col-md-12">
          <div class="col-md-12">
            <div class="download-content mt-2">
              <p
                class=""
                style="
                  font-family: 'nunito', sans-serif;
                  font-size: 1.4em;
                  font-weight: 400;
                  color: #000000;
                "
              >
                The Superintendents' Commission is a premier educational
                organization composed of elementary and secondary
                superintendents and school districts of the Suburban Cook and
                Collar Counties dedicated to providing quality educational
                opportunities for all students.

                <br /><br />

                They believe that partnering with businesses and institutions of
                higher learning provides support for educational initiatives
                that are beneficial to the school community.
              </p>

              <div class="row mt-5">
                <div
                  class="col-md-12 col-lg-12"
                  style="
                    text-align: center;
                    font-family: 'nunito', sans-serif;
                    font-size: 1.3em;
                    font-weight: 400;
                    color: #000000;
                  "
                >
                  Dr. Johnnie Thomas, President
                </div>
              </div>

              <div class="row mt-2 mb-5">
                <div
                  class="col-md-12 col-lg-12"
                  style="
                    text-align: center;
                    font-family: 'nunito', sans-serif;
                    font-size: 1.3em;
                    font-weight: 400;
                    color: #000000;
                  "
                >
                  Dr. Creg Williams, Executive Director
                </div>
              </div>

              <!-- <br />
              <h1
                class="text-center"
                style="
                  font-family: 'nunito', sans-serif;
                  font-size: 2em;
                  font-weight: 700;
                  color: red;
                "
              >
                Purchase Tickets below<br />
              </h1> -->
            </div>

            <!-- <section class="container">
              <h2
                class="mt-5 text-center"
                style="
                  font-family: 'nunito', sans-serif;
                  font-size: 2em;
                  font-weight: 700;
                  color: #000000;
                "
              >
                Superintendents
              </h2> -->
            <!-- <p class="lead">
                  We are also accepting Cash App, Paypal, Zelle and Venmo for ticket
                  payments
                </p> -->

            <!-- <div class="row">
                <div class="col-md-4 col-lg-4 align-items-center">
                  <div class="">
                    <img
                      src="https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/mylesite/uncf_superintendents/1_dr_m_alexander_dis_132.jpg"
                      alt="Saturday Event"
                      class="w-100 img-fluid"
                    />
                  </div>
                </div>
                <div
                  class="col-md-4 col-lg-4 justify-content-center align-items-center"
                >
                  <div class="">
                    <img
                      src="https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/mylesite/uncf_superintendents/2_dr_l_bridges_dis_152.jpg"
                      alt="Saturday Event"
                      class="w-100 img-fluid"
                    />
                  </div>
                </div>

                <div
                  class="col-md-4 col-lg-4 justify-content-center align-items-center"
                >
                  <div class="">
                    <img
                      src="https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/mylesite/uncf_superintendents/3_dr_t_brunson_dis_159.jpg"
                      alt="Saturday Event"
                      class="w-100 img-fluid"
                    />
                  </div>
                </div>
              </div>

              <div class="row">
                <div class="col-md-4 col-lg-4 align-items-center">
                  <div class="">
                    <img
                      src="https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/mylesite/uncf_superintendents/4_dr_j_carmine_dis_163.jpg"
                      alt="Saturday Event"
                      class="w-100 img-fluid"
                    />
                  </div>
                </div>
                <div
                  class="col-md-4 col-lg-4 justify-content-center align-items-center"
                >
                  <div class="">
                    <img
                      src="https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/mylesite/uncf_superintendents/5_dr_Lisa_Cole_dis_125.jpg"
                      alt="Saturday Event"
                      class="w-100 img-fluid"
                    />
                  </div>
                </div>

                <div
                  class="col-md-4 col-lg-4 justify-content-center align-items-center"
                >
                  <div class="">
                    <img
                      src="https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/mylesite/uncf_superintendents/6_dr_n_cunningham_dis_205.jpg"
                      alt="Saturday Event"
                      class="w-100 img-fluid"
                    />
                  </div>
                </div>
              </div>

              <div class="row">
                <div class="col-md-4 col-lg-4 align-items-center">
                  <div class="">
                    <img
                      src="https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/mylesite/uncf_superintendents/7_dr_b_davis_dis_162_and_sl_prep.jpg"
                      alt="Saturday Event"
                      class="w-100 img-fluid"
                    />
                  </div>
                </div>
                <div
                  class="col-md-4 col-lg-4 justify-content-center align-items-center"
                >
                  <div class="">
                    <img
                      src="https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/mylesite/uncf_superintendents/8_dr_a_edison_dis_143.5.jpg"
                      alt="Saturday Event"
                      class="w-100 img-fluid"
                    />
                  </div>
                </div>

                <div
                  class="col-md-4 col-lg-4 justify-content-center align-items-center"
                >
                  <div class="">
                    <img
                      src="https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/mylesite/uncf_superintendents/9_dr_d_evans_dis_157.jpg"
                      alt="Saturday Event"
                      class="w-100 img-fluid"
                    />
                  </div>
                </div>
              </div>

              <div class="row">
                <div class="col-md-4 col-lg-4 align-items-center">
                  <div class="">
                    <img
                      src="https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/mylesite/uncf_superintendents/10_j_farrell_dis_150.jpg"
                      alt="Saturday Event"
                      class="w-100 img-fluid"
                    />
                  </div>
                </div>
                <div
                  class="col-md-4 col-lg-4 justify-content-center align-items-center"
                >
                  <div class="">
                    <img
                      src="https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/mylesite/uncf_superintendents/11_dr_t_halliman_dis_speed_joint_agreement.jpg"
                      alt="Saturday Event"
                      class="w-100 img-fluid"
                    />
                  </div>
                </div>

                <div
                  class="col-md-4 col-lg-4 justify-content-center align-items-center"
                >
                  <div class="">
                    <img
                      src="https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/mylesite/uncf_superintendents/12_dr_g_jackson_dis_169.jpg"
                      alt="Saturday Event"
                      class="w-100 img-fluid"
                    />
                  </div>
                </div>
              </div>

              <div class="row">
                <div class="col-md-4 col-lg-4 align-items-center">
                  <div class="">
                    <img
                      src="https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/mylesite/uncf_superintendents/12_1_dr_j_jackson_dis_92.jpg"
                      alt="Saturday Event"
                      class="w-100 img-fluid"
                    />
                  </div>
                </div>
                <div
                  class="col-md-4 col-lg-4 justify-content-center align-items-center"
                >
                  <div class="">
                    <img
                      src="https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/mylesite/uncf_superintendents/13_dr_j_jordan-c_williams_dis_147.jpg"
                      alt="Saturday Event"
                      class="w-100 img-fluid"
                    />
                  </div>
                </div>

                <div
                  class="col-md-4 col-lg-4 justify-content-center align-items-center"
                >
                  <div class="">
                    <img
                      src="https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/mylesite/uncf_superintendents/14_dr_d_julius-t_obrien_dis_144.jpg"
                      alt="Saturday Event"
                      class="w-100 img-fluid"
                    />
                  </div>
                </div>
              </div>

              <div class="row">
                <div class="col-md-4 col-lg-4 align-items-center">
                  <div class="">
                    <img
                      src="https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/mylesite/uncf_superintendents/15_dr_b_karvelas_dis_209.jpg"
                      alt="Saturday Event"
                      class="w-100 img-fluid"
                    />
                  </div>
                </div>
                <div
                  class="col-md-4 col-lg-4 justify-content-center align-items-center"
                >
                  <div class="">
                    <img
                      src="https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/mylesite/uncf_superintendents/16_b_lindsay_dis_167.jpg"
                      alt="Saturday Event"
                      class="w-100 img-fluid"
                    />
                  </div>
                </div>

                <div
                  class="col-md-4 col-lg-4 justify-content-center align-items-center"
                >
                  <div class="">
                    <img
                      src="https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/mylesite/uncf_superintendents/17_dr_d_meighan_dis_160.jpg"
                      alt="Saturday Event"
                      class="w-100 img-fluid"
                    />
                  </div>
                </div>
              </div>

              <div class="row">
                <div class="col-md-4 col-lg-4 align-items-center">
                  <div class="">
                    <img
                      src="https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/mylesite/uncf_superintendents/18_dr_e_milhouse_dis_171.jpg"
                      alt="Saturday Event"
                      class="w-100 img-fluid"
                    />
                  </div>
                </div>
                <div
                  class="col-md-4 col-lg-4 justify-content-center align-items-center"
                >
                  <div class="">
                    <img
                      src="https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/mylesite/uncf_superintendents/19_dr_k_nalls_dis_172.jpg"
                      alt="Saturday Event"
                      class="w-100 img-fluid"
                    />
                  </div>
                </div>

                <div
                  class="col-md-4 col-lg-4 justify-content-center align-items-center"
                >
                  <div class="">
                    <img
                      src="https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/mylesite/uncf_superintendents/20_dr_l_navarre_dis_206.jpg"
                      alt="Saturday Event"
                      class="w-100 img-fluid"
                    />
                  </div>
                </div>
              </div>

              <div class="row">
                <div class="col-md-4 col-lg-4 align-items-center">
                  <div class="">
                    <img
                      src="https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/mylesite/uncf_superintendents/21_k_nohelty_dis_148.jpg"
                      alt="Saturday Event"
                      class="w-100 img-fluid"
                    />
                  </div>
                </div>
                <div
                  class="col-md-4 col-lg-4 justify-content-center align-items-center"
                >
                  <div class="">
                    <img
                      src="https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/mylesite/uncf_superintendents/22_dr_a_rice_dis_156.jpg"
                      alt="Saturday Event"
                      class="w-100 img-fluid"
                    />
                  </div>
                </div>

                <div
                  class="col-md-4 col-lg-4 justify-content-center align-items-center"
                >
                  <div class="">
                    <img
                      src="https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/mylesite/uncf_superintendents/23_dr_j_robinzine_dis_215.jpg"
                      alt="Saturday Event"
                      class="w-100 img-fluid"
                    />
                  </div>
                </div>
              </div>

              <div class="row">
                <div class="col-md-4 col-lg-4 align-items-center">
                  <div class="">
                    <img
                      src="https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/mylesite/uncf_superintendents/24_dr_b_sakora_dis_228.jpg"
                      alt="Saturday Event"
                      class="w-100 img-fluid"
                    />
                  </div>
                </div>
                <div
                  class="col-md-4 col-lg-4 justify-content-center align-items-center"
                >
                  <div class="">
                    <img
                      src="https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/mylesite/uncf_superintendents/25_dr_d_simpson_leak_dis_168.jpg"
                      alt="Saturday Event"
                      class="w-100 img-fluid"
                    />
                  </div>
                </div>

                <div
                  class="col-md-4 col-lg-4 justify-content-center align-items-center"
                >
                  <div class="">
                    <img
                      src="https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/mylesite/uncf_superintendents/26_dr_m_smith_dis_98.jpg"
                      alt="Saturday Event"
                      class="w-100 img-fluid"
                    />
                  </div>
                </div>
              </div>

              <div class="row">
                <div class="col-md-4 col-lg-4 align-items-center">
                  <div class="">
                    <img
                      src="https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/mylesite/uncf_superintendents/N_smith_dis_259.jpg"
                      alt="Saturday Event"
                      class="w-100 img-fluid"
                    />
                  </div>
                </div>
                <div
                  class="col-md-4 col-lg-4 justify-content-center align-items-center"
                >
                  <div class="">
                    <img
                      src="https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/mylesite/uncf_superintendents/27_dr_k_spells_dis_152-5.jpg"
                      alt="Saturday Event"
                      class="w-100 img-fluid"
                    />
                  </div>
                </div>

                <div
                  class="col-md-4 col-lg-4 justify-content-center align-items-center"
                >
                  <div class="">
                    <img
                      src="https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/mylesite/uncf_superintendents/28_dr_j_thomas_dis_227.jpg"
                      alt="Saturday Event"
                      class="w-100 img-fluid"
                    />
                  </div>
                </div>
              </div>

              <div class="row">
                <div class="col-md-4 col-lg-4 align-items-center">
                  <div class="">
                    <img
                      src="https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/mylesite/uncf_superintendents/29_dr_s_thomas_dis_echo_join_agreement.jpg"
                      alt="Saturday Event"
                      class="w-100 img-fluid"
                    />
                  </div>
                </div>
                <div
                  class="col-md-4 col-lg-4 justify-content-center align-items-center"
                >
                  <div class="">
                    <img
                      src="https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/mylesite/uncf_superintendents/30_dr_s_wakeley_dis_233.jpg"
                      alt="Saturday Event"
                      class="w-100 img-fluid"
                    />
                  </div>
                </div>

                <div
                  class="col-md-4 col-lg-4 justify-content-center align-items-center"
                >
                  <div class="">
                    <img
                      src="https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/mylesite/uncf_superintendents/31_dr_g_walters_dis_111.jpg"
                      alt="Saturday Event"
                      class="w-100 img-fluid"
                    />
                  </div>
                </div>
              </div>

              <div class="row">
                <div class="col-md-4 col-lg-4 align-items-center">
                  <div class="">
                    <img
                      src="https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/mylesite/uncf_superintendents/32_dr_m_white_dis_149.jpg"
                      alt="Saturday Event"
                      class="w-100 img-fluid"
                    />
                  </div>
                </div>
                <div
                  class="col-md-4 col-lg-4 justify-content-center align-items-center"
                > -->
            <!-- <div class="">
                      <img
                        alt="Saturday Event"
                        class="w-100 img-fluid"
                      />
                    </div> -->
            <!-- </div> -->

            <!-- <div
                  class="col-md-4 col-lg-4 justify-content-center align-items-center"
                > -->
            <!-- <div class="">
                      <img alt="Saturday Event" class="w-100 img-fluid" />
                    </div> -->
            <!-- </div>
              </div>

              <div class="row mt-4">
                <div class="col-md-2 col-lg-2 align-items-center"></div>
                <div
                  class="col-md-8 col-lg-8 justify-content-center align-items-center"
                >
                  <div class="">
                    <img
                      src="https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/mylesite/uncf_superintendents/Retired_Superintendents.jpg"
                      alt="Saturday Event"
                      class="w-100 img-fluid"
                    />
                  </div>
                </div>

                <div
                  class="col-md-2 col-lg-2 justify-content-center align-items-center"
                ></div>
              </div>
            </section> -->

            <!-- Section 2 -->
            <!-- Tickets Section starts -->
            <div class="row justify-content-center">
              <div class="col-md-12">
                <div class="py-5">
                  <div class="text-center">
                    <h3
                      class="w-auto d-inline-block mb-2"
                      style="
                        font-family: 'nunito', sans-serif;
                        font-size: 2.2em;
                        font-weight: 700;
                        color: #000000;
                      "
                    >
                      Sponsorship Packages
                    </h3>
                  </div>

                  <div class="d-flex flex-row flex-wrap justify-content-center">
                    <div
                      class="card mx-4 mt-4"
                      style="
                        max-width: 450px !important;
                        border-radius: 10px;
                        overflow: hidden;
                      "
                      v-bind:key="objk"
                      v-for="(obj, objk) in section2Filter(TicketsResult)"
                    >
                      <div class="card-header MYLE-uncf3rd white text-center">
                        {{ obj.ticketName }}
                      </div>
                      <div
                        class="card-body"
                        style="display: flex; flex-direction: column"
                      >
                        <p
                          v-bind:key="w"
                          v-for="(msgs, w) in filteredMessages(obj)"
                        >
                          - {{ msgs }}
                        </p>
                        <div class="text-center" style="margin-top: auto">
                          <p v-if="obj.ticketType != 'FREE'">
                            <b class="font-weight-bold">Total Price:</b>
                            ${{ obj.price.toFixed(2) }}
                          </p>
                          <p
                            v-bind:key="linek"
                            v-for="(line, linek) in obj.description"
                          >
                            {{ line }}
                          </p>
                          <button
                            :disabled="
                              !obj.availableTickets ||
                              obj.status == 'EXPIRED' ||
                              obj.status == 'COMING_SOON'
                            "
                            v-bind:class="{
                              'bg-disable':
                                obj.status == 'EXPIRED' ||
                                !obj.availableTickets ||
                                obj.status == 'COMING_SOON',
                            }"
                            class="btn solid-btn-uncf3rd"
                            @click="openmodel(obj.ticketCode)"
                            style="
                              pointer-events: all;
                              cursor: pointer;
                              font-family: 'nunito', sans-serif;
                              font-weight: 700;
                              font-size: 1.2em;
                              font-weight: bold;
                            "
                          >
                            {{
                              obj.status == "EXPIRED" || !obj.availableTickets
                                ? "Sold Out"
                                : obj.status == "COMING_SOON"
                                ? "Coming Soon"
                                : "Purchase"
                            }}
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <h1
                  class="text-center mt-3"
                  style="
                    font-family: 'nunito', sans-serif;
                    font-size: 1.1em;
                    font-weight: 700;
                    color: #000000;
                  "
                >
                  The UNCF Scholarship Gala is powered by MYLE and your credit
                  card statement will show MYLE as the merchant of record.
                </h1>
              </div>
            </div>

            <!-- Tickets Section starts -->
            <div class="row justify-content-center">
              <div class="col-md-12">
                <div class="py-5">
                  <div class="text-center">
                    <!-- <h1
                        class="text-center"
                        style="
                          font-family: 'Quicksand', sans-serif;
                          font-weight: 500;
                          font-size: 22px;
                          color: red;
                        "
                      >
                        The purchase of tickets and sponsorships will close on
                        Monday, Feb. 27th at 5pm CT.
                      </h1> -->
                    <h3
                      class="w-auto d-inline-block mb-2"
                      style="
                        font-family: 'nunito', sans-serif;
                        font-size: 2.2em;
                        font-weight: 700;
                        color: #000000;
                      "
                    >
                      Ticket Options
                    </h3>
                  </div>

                  <div class="d-flex flex-row flex-wrap justify-content-center">
                    <div
                      class="card mx-4 mt-4"
                      style="
                        max-width: 450px !important;
                        border-radius: 10px;
                        overflow: hidden;
                      "
                      v-bind:key="objk"
                      v-for="(obj, objk) in section1Filter(TicketsResult)"
                    >
                      <div class="card-header MYLE-uncf3rd white text-center">
                        {{ obj.ticketName }}
                      </div>
                      <div
                        class="card-body"
                        style="display: flex; flex-direction: column"
                      >
                        <p
                          v-bind:key="w"
                          v-for="(msgs, w) in filteredMessages(obj)"
                        >
                          - {{ msgs }}
                        </p>
                        <div class="text-center" style="margin-top: auto">
                          <p v-if="obj.ticketType != 'FREE'">
                            <b class="font-weight-bold">Total Price:</b>
                            ${{ obj.price.toFixed(2) }}
                          </p>
                          <p
                            v-bind:key="linek"
                            v-for="(line, linek) in obj.description"
                          >
                            {{ line }}
                          </p>
                          <button
                            :disabled="
                              !obj.availableTickets ||
                              obj.status == 'EXPIRED' ||
                              obj.status == 'COMING_SOON'
                            "
                            v-bind:class="{
                              'bg-disable':
                                obj.status == 'EXPIRED' ||
                                !obj.availableTickets ||
                                obj.status == 'COMING_SOON',
                            }"
                            class="btn solid-btn-uncf3rd"
                            @click="openmodel(obj.ticketCode)"
                            style="
                              pointer-events: all;
                              cursor: pointer;
                              font-family: 'nunito', sans-serif;
                              font-weight: 700;
                              font-size: 1.2em;
                              font-weight: bold;
                            "
                          >
                            {{
                              obj.status == "EXPIRED" || !obj.availableTickets
                                ? "Sold Out"
                                : obj.status == "COMING_SOON"
                                ? "Coming Soon"
                                : "Purchase"
                            }}
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <!-- Section 2 -->
            <!-- Tickets Section starts -->
            <div class="row justify-content-center">
              <div class="col-md-12">
                <div class="py-5">
                  <div class="text-center">
                    <h3
                      class="w-auto d-inline-block mb-2"
                      style="
                        font-family: 'nunito', sans-serif;
                        font-size: 2.2em;
                        font-weight: 700;
                        color: #000000;
                      "
                    >
                      Donations
                    </h3>
                  </div>

                  <div class="d-flex flex-row flex-wrap justify-content-center">
                    <div
                      class="card mx-4 mt-4"
                      style="
                        max-width: 450px !important;
                        border-radius: 10px;
                        overflow: hidden;
                      "
                      v-bind:key="objk"
                      v-for="(obj, objk) in donationSectionFilter(
                        TicketsResult
                      )"
                    >
                      <div class="card-header MYLE-uncf3rd white text-center">
                        {{ obj.ticketName }}
                      </div>
                      <div
                        class="card-body"
                        style="display: flex; flex-direction: column"
                      >
                        <p
                          v-bind:key="w"
                          v-for="(msgs, w) in filteredMessages(obj)"
                        >
                          - {{ msgs }}
                        </p>
                        <div class="text-center" style="margin-top: auto">
                          <p v-if="obj.ticketType != 'FREE'">
                            <b class="font-weight-bold">Total Amount:</b>
                            ${{ obj.price.toFixed(2) }}
                          </p>
                          <p
                            v-bind:key="linek"
                            v-for="(line, linek) in obj.description"
                          >
                            {{ line }}
                          </p>
                          <button
                            :disabled="
                              !obj.availableTickets ||
                              obj.status == 'EXPIRED' ||
                              obj.status == 'COMING_SOON'
                            "
                            v-bind:class="{
                              'bg-disable':
                                obj.status == 'EXPIRED' ||
                                !obj.availableTickets ||
                                obj.status == 'COMING_SOON',
                            }"
                            class="btn solid-btn-uncf3rd"
                            @click="openmodel(obj.ticketCode)"
                            style="
                              pointer-events: all;
                              cursor: pointer;
                              font-family: 'nunito', sans-serif;
                              font-weight: 700;
                              font-size: 1.2em;
                              font-weight: bold;
                            "
                          >
                            {{
                              obj.status == "EXPIRED" || !obj.availableTickets
                                ? "Sold Out"
                                : obj.status == "COMING_SOON"
                                ? "Coming Soon"
                                : "Donate"
                            }}
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- Description section ends -->
    </div>

    <div
      class="row w-100 m-0 promo-section MYLE-lite-green"
      style="background-color: #2b292a"
    >
      <div class="col-lg-12 col-md-12">
        <div class="mt-5">
          <div class="section-heading mb-5">
            <h2 class="text-center text-white">
              Download today and try it for yourself
            </h2>
            <div
              class="download-btn mt-4 text-center animate__animated animate__fadeIn"
            >
              <a
                href=" https://apps.apple.com/us/app/myle-create-discover-events-curated-for-you/id1162354481"
                class="btn google-play-btn mr-3"
                target="_blank"
                rel="noopener noreferrer"
                ><span class="ti-apple"></span> App Store</a
              >
              <a
                href="https://play.google.com/store/apps/details?id=xyz.getmyle.myle&hl=en&gl=US"
                class="btn google-play-btn mr-3"
                target="_blank"
                rel="noopener noreferrer"
                ><span class="ti-android"></span> Google Play</a
              >
              <a
                href="https://web.myle.com"
                class="btn google-play-btn"
                target="_blank"
                rel="noopener noreferrer"
                ><span class="ti-world"></span> MYLE Web</a
              >
            </div>
          </div>
        </div>
      </div>
    </div>
    <site-footer :show-subscribe="true" />
  </div>
</template>

<script>
// Component Imports

import NavBar from "../../views/commons/NavBar";
import SiteFooter from "../../views/index-five/SiteFooter";
import { StripeCheckout } from "@vue-stripe/vue-stripe";
import axios from "axios";
import confirmationPopup from "../../components/confirmationPopup";
import VueElementLoading from "vue-element-loading";
// import { VueFlux, FluxPreloader, FluxControls } from "vue-flux";

// defining component state
export default {
  name: "newyear",
  data() {
    return {
      names: {},
      showsuccess: false,
      nContactName: "",
      nEmailAddress: "",
      nStreetAddress: "",
      nState: "Alabama",
      nZip: "",
      nCity: "",
      nDistrict: "Dr. Michelle Smith, Berwyn School District 98",
      states: [
        { "States in USA": "Alabama" },
        { "States in USA": "Alaska" },
        { "States in USA": "Arizona" },
        { "States in USA": "Arkansas" },
        { "States in USA": "Califonria" },
        { "States in USA": "Colorado" },
        { "States in USA": "Connecticut" },
        { "States in USA": "Delaware" },
        { "States in USA": "Dictrict of Columbia" },
        { "States in USA": "Florida" },
        { "States in USA": "Georgia" },
        { "States in USA": "Hawaii" },
        { "States in USA": "Idaho" },
        { "States in USA": "Illinois" },
        { "States in USA": "Indiana" },
        { "States in USA": "Iowa" },
        { "States in USA": "Kansas" },
        { "States in USA": "Kentucky" },
        { "States in USA": "Louisiana" },
        { "States in USA": "Maine" },
        { "States in USA": "Maryland" },
        { "States in USA": "Massachusetts" },
        { "States in USA": "Michigan" },
        { "States in USA": "Minnesota" },
        { "States in USA": "Mississippi" },
        { "States in USA": "Missouri" },
        { "States in USA": "Montana" },
        { "States in USA": "Nebraska" },
        { "States in USA": "Nevada" },
        { "States in USA": "New Hampshire" },
        { "States in USA": "New Jersey" },
        { "States in USA": "New Mexico" },
        { "States in USA": "New York" },
        { "States in USA": "North Carolina" },
        { "States in USA": "North Dakota" },
        { "States in USA": "Ohio" },
        { "States in USA": "Oklahoma" },
        { "States in USA": "Oregon" },
        { "States in USA": "Pennsylvania" },
        { "States in USA": "Rhode Island" },
        { "States in USA": "South Carolina" },
        { "States in USA": "South Dakota" },
        { "States in USA": "Tennessee" },
        { "States in USA": "Texas" },
        { "States in USA": "Utah" },
        { "States in USA": "Vermont" },
        { "States in USA": "Virginia" },
        { "States in USA": "Washington" },
        { "States in USA": "West Virginia" },
        { "States in USA": "Wisconsin" },
        { "States in USA": "Wyoming" },
      ],
      district: [
        // {
        //   Dr: {
        //     superintendent: "No District Affiliation",
        //   },
        // },

        // {
        //   Dr: {
        //     superintendent:
        //       "Dr. Blondean Davis, Southland College Prep Charter School",
        //   },
        // },

        {
          Dr: {
            superintendent: "Dr. Sandra Thomas, Echo Joint Agreement",
          },
        },

        {
          Dr: {
            superintendent: "Dr. Tina Halliman, SPEED Joint Agreement",
          },
        },

        {
          Dr: {
            superintendent: "District 92 - Dr. Janiece Jackson, Lindop School",
          },
        },

        {
          Dr: {
            superintendent: "District 98 - Dr. Michelle Smith, Berwyn School",
          },
        },

        {
          Dr: {
            superintendent:
              "District 111 - Dr. Genevra Walters, Kankakee School",
          },
        },

        {
          Dr: {
            superintendent: "District 125 - Dr. Lisa Cole, Atwood Heights",
          },
        },

        {
          Dr: {
            superintendent:
              "District 132 - Dr. Marcus Alexander, Calumet School",
          },
        },

        {
          Dr: {
            superintendent:
              "District 143.5 - Dr. Anthony Edison, Posen Robbins School",
          },
        },

        {
          Dr: {
            superintendent:
              "District 144 - Dr. Denise Julius, Dr. Terry O'brien, Prairie Hills School",
          },
        },

        {
          Dr: {
            superintendent:
              "District 147 - Dr. Jerry Jordan, Dr. Creg Williams, Harvey Dixmoor School",
          },
        },

        {
          Dr: {
            superintendent:
              "District 148 - Kevin Nohelty, Dolton Riverdale School",
          },
        },

        {
          Dr: {
            superintendent: "District 149 - Dr. Maureen White, Dolton School",
          },
        },

        {
          Dr: {
            superintendent:
              "District 150 - Jerome Farrell, South Holland School",
          },
        },

        {
          Dr: {
            superintendent: "District 152 - Dr. Lela Bridges, Harvey School",
          },
        },

        {
          Dr: {
            superintendent:
              "District 152.5 - Dr. Kenneth Spells, East Hazel Crest School",
          },
        },

        {
          Dr: {
            superintendent: "District 156 - Dr. Anita Rice, Lincoln School",
          },
        },

        {
          Dr: {
            superintendent:
              "District 157 - Dr. Dwayne Evans, Calumet City School",
          },
        },

        {
          Dr: {
            superintendent:
              "District 159 - Dr. Tiffany Brunson, Elementary School",
          },
        },

        {
          Dr: {
            superintendent:
              "District 160 - Dr. Duane Meighan, Country Club Hills School",
          },
        },

        {
          Dr: {
            superintendent: "District 162 - Dr. Blondean Davis, Matteson/SCP",
          },
        },

        {
          Dr: {
            superintendent:
              "District 163 - Dr. Joyce Carmine, Park Forest School",
          },
        },

        {
          Dr: {
            superintendent: "District 167 - Bethany Lindsay, Brookwood School",
          },
        },

        {
          Dr: {
            superintendent:
              "District 168 - Dr. Donna Simpson Leak, Sauk Village School",
          },
        },

        {
          Dr: {
            superintendent: "District 169 - Dr. Gregory Jackson, Ford Heights",
          },
        },

        {
          Dr: {
            superintendent:
              "District 171 - Dr. Erika Millhouse, Sunnybrook School",
          },
        },

        {
          Dr: {
            superintendent: "District 172 - Dr. Kim Nalls, Sandridge School",
          },
        },

        {
          Dr: {
            superintendent:
              "District 205 - Dr. Nathaniel Cunningham, Thornton HS",
          },
        },

        {
          Dr: {
            superintendent: "District 206 - Dr. Lenell Navarre, Bloom Township",
          },
        },

        {
          Dr: {
            superintendent: "District 209 - Dr. Bessie Karvelas, Proviso HS",
          },
        },

        {
          Dr: {
            superintendent:
              "District 215 - Dr. John M. Robinzine, Thornton Fractional HS",
          },
        },

        {
          Dr: {
            superintendent:
              "District 227 - Dr. Johnnie Thomas, Rich Township HS",
          },
        },

        {
          Dr: {
            superintendent: "District 228 - Dr. Brad Sikora, Breman HS",
          },
        },

        {
          Dr: {
            superintendent:
              "District 233 - Dr. Scott Wakeley, Homewood Flossmoor HS",
          },
        },

        {
          Dr: {
            superintendent:
              "District 259 - Nicole Terrell Smith, Pembroke School",
          },
        },

        {
          Dr: {
            superintendent: "Dr. Cynthia Broughton",
          },
        },

        {
          Dr: {
            superintendent: "Dr. Eric King",
          },
        },

        {
          Dr: {
            superintendent: "Dr. Gloria Davis",
          },
        },

        {
          Dr: {
            superintendent: "Dr. John Sawyer",
          },
        },

        {
          Dr: {
            superintendent: "Dr. Louise Coleman",
          },
        },

        {
          Dr: {
            superintendent: "Dr. Mable Alfred",
          },
        },

        {
          Dr: {
            superintendent: "Dr. Sheila Harrison-Williams",
          },
        },

        {
          Dr: {
            superintendent: "Dr. Willie Mack",
          },
        },

        // {
        //   Dr: {
        //     superintendent: "John Thomas",
        //   },
        // },

        {
          Dr: {
            superintendent: "Kay Giles",
          },
        },

        {
          Dr: {
            superintendent: "Ms. Lisa Cole Hide",
          },
        },

        {
          Dr: {
            superintendent: "Phylistine Murphy",
          },
        },

        {
          Dr: {
            superintendent: "Theodis Swopes",
          },
        },
      ],
      agePlaceholders: [
        "First Child Age",
        "Second Child Age",
        "Third Child Age",
        "Fourth Child Age",
      ],
      vchange: true,
      validSection: true,
      efName: "",
      elName: "",
      eEmail: "",
      business_organization_name: "",
      emNumber: "",
      eTitle: "",
      eRefferedBy: "",
      efatherName: "",
      efEmail: "",
      ePhoneNumber: "",
      eOrgNumber: "",
      age: {},
      agecount: {
        tk_U1IGqa6dzMCTjln: [1],
        tk_eVzbCBqzSuFPfd5: [1, 2],
        tk_g19EFw7kOMyfuL8: [1, 2, 3],
        tk_rFyeu7irKUhlI1U: [1, 2, 3, 4],
      },
      section1: ["tk_dU7a7I9AiJYd48x", "tk_QMzy31e4IJKJvHp"],
      section2: [
        "tk_YOzzRx5mOgaeIu2",
        "tk_xKPIrcyXYXEmOyC",
        "tk_S6iXCYKjagcEBXN",
        "tk_sCEleEPcdT4g8da",
      ],
      donationsSection: [
        "tk_9wu1JY7j3DxEjiZ",
        "tk_xoeXu02Q0xiD9FX",
        "tk_lixSrnyzRy8SLWI",
        "tk_1f7iSJK5Nqp70tW",
        "tk_CF2pAPuXAubu7Zq",
        "tk_5Q67zL0t5U2x5mu",
        "tk_6YLC1TkSt1QfxV7",
        "tk_zTViqsmCU60HkvT",
        "tk_WjtoOJep8CeNBfX",
      ],

      redeempromo: "",
      redeemPriceId: "",
      redeemData: null,
      reserveFormAccept: false,
      tkList: {
        tk_dU7a7I9AiJYd48x: {
          messages: [
            "Ticket Price: $100.00",
            "General Admission to Scholarship Gala",
          ],
        },

        tk_QMzy31e4IJKJvHp: {
          messages: [
            "Ticket Price: $250.00",
            "VIP Seating",
            "VIP Reception w/Superintendents Commission",
            "Program Booklet Listing",
          ],
        },

        tk_YOzzRx5mOgaeIu2: {
          messages: [
            "Ticket Price: $10000.00",
            "Special Recognition and introduction during program with remarks",
            "1 Premium Reserved Table of 10",
            "Prominent Placement Full Page Ad: Full (bleed) 7.25” wide x 9.25” or Full (no bleed) 6.5” w x 8.5”",
            "VIP Reception w/Superintendents Commission",
          ],
        },

        tk_xKPIrcyXYXEmOyC: {
          messages: [
            "Ticket Price: $5000.00",
            "Recognition and Introduction during program",
            "1 Reserved Table of 10",
            "Half Page Ad: 6.5” wide x 4.125”",
            "VIP Reception w/Superintendents Commission",
          ],
        },

        tk_S6iXCYKjagcEBXN: {
          messages: [
            "Ticket Price: $2500.00",
            "1 Reserved Table of 10",
            "Quarter Page Ad: Quarter 3.125” wide x 4.125”",
          ],
        },

        tk_sCEleEPcdT4g8da: {
          messages: [
            "Ticket Price: $1000.00",
            "1 Reserved Table of 10",
            "Listed as Bronze sponsor in program book",
          ],
        },

        tk_9wu1JY7j3DxEjiZ: {
          messages: ["Donation Amount: $25"],
        },

        tk_xoeXu02Q0xiD9FX: {
          messages: ["Donation Amount: $50"],
        },

        tk_lixSrnyzRy8SLWI: {
          messages: ["Donation Amount: $100"],
        },

        tk_1f7iSJK5Nqp70tW: {
          messages: ["Donation Amount: $250"],
        },

        tk_CF2pAPuXAubu7Zq: {
          messages: ["Donation Amount: $500"],
        },

        tk_5Q67zL0t5U2x5mu: {
          messages: ["Donation Amount: $1000"],
        },

        tk_6YLC1TkSt1QfxV7: {
          messages: ["Donation Amount: $2500"],
        },

        tk_zTViqsmCU60HkvT: {
          messages: ["Donation Amount: $5000"],
        },

        tk_WjtoOJep8CeNBfX: {
          messages: ["Donation Amount: $10000"],
        },
      },

      priceIds: {
        tk_dU7a7I9AiJYd48x: "price_1QK2J4D0vTZ4QB9cJ1GttDRz",
        tk_QMzy31e4IJKJvHp: "price_1QK2J5D0vTZ4QB9ciN2XRaez",

        tk_YOzzRx5mOgaeIu2: "price_1QK2J5D0vTZ4QB9ckQim5E9u",
        tk_xKPIrcyXYXEmOyC: "price_1QK2J6D0vTZ4QB9crF6L5SaK",
        tk_S6iXCYKjagcEBXN: "price_1QK2J6D0vTZ4QB9cRqTQu1HC",
        tk_sCEleEPcdT4g8da: "price_1QK2J7D0vTZ4QB9cHKIxAe0u",

        tk_9wu1JY7j3DxEjiZ: "price_1Nvnc4D0vTZ4QB9c4pFKKVWF",
        tk_xoeXu02Q0xiD9FX: "price_1Nvnc4D0vTZ4QB9cXjjY4dQF",
        tk_lixSrnyzRy8SLWI: "price_1Nvnc5D0vTZ4QB9cOzAo6qn4",
        tk_1f7iSJK5Nqp70tW: "price_1Nvnc5D0vTZ4QB9ccFTGI9QJ",
        tk_CF2pAPuXAubu7Zq: "price_1Nvnc6D0vTZ4QB9cXwitFSyY",
        tk_5Q67zL0t5U2x5mu: "price_1Nvnc6D0vTZ4QB9cKdhB0Mvx",
        tk_6YLC1TkSt1QfxV7: "price_1Nvnc7D0vTZ4QB9cuvaajUz4",
        tk_zTViqsmCU60HkvT: "price_1Nvnc7D0vTZ4QB9cTQPE6sLS",
        tk_WjtoOJep8CeNBfX: "price_1Nvnc8D0vTZ4QB9caV0MQU4Z",
      },

      countsListShow: [],
      eventTicketResult: [],
      selectedAccor: null,
      ticketForm: {
        name: "",
        email: "",
        phone: "",
        ticketCount: 0,
        ticketName: "",
        ticketId: "",
        method: "",
        accept: "",
        file: null,
      },
      loadStyle: {
        color: "#ffffff",
        "font-weight": "bold",
        "letter-spacing": "1rem",
        "font-size": "18px",
      },
      selected: 0,
      fullname: "",
      email: "",
      tickcount: 1,
      phone: "",
      isSubmitted: false,
      tickcountlist: [],
      show: false,
      checkout: {
        clientReferenceId: null,
        publishKey:
          "pk_live_51JEQYTD0vTZ4QB9cnmoJMhJQrCybltMAeG2uN3utIarQ342JyZSyyWFXgEdriazLZRFAR1Crne2E1d9PNQFVGxbw00RTp4QVjn",
      },
      TicketsResult: [],
      vfOptions: {
        autoplay: true,
      },
      vfTransitions: [
        "slide",
        "blinds2d",
        "explode",
        "fade",
        "round2",
        "swipe",
        "warp",
        "zip",
      ],
      vfImages: [
        "https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/mylesite/logo.jpeg",
        "https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/mylesite/logo.jpeg",
        "https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/mylesite/logo.jpeg",
      ],
      vfEventImgs: [
        "https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/stadiumImages/Stadium_1.jpeg",
        "https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/stadiumImages/Stadium_2.jpeg",
        "https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/stadiumImages/Stadium_3.jpeg",
        "https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/stadiumImages/Stadium_4.jpeg",
        "https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/stadiumImages/Stadium_5.jpeg",
        "https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/stadiumImages/Stadium_6.png",
        "https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/stadiumImages/Stadium_7.png",
        "https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/stadiumImages/Stadium_8.png",
      ],
    };
  },
  components: {
    NavBar,
    SiteFooter,
    confirmationPopup,
    VueElementLoading,
    // VueFlux,
    // FluxPreloader,
    // FluxControls,
    StripeCheckout,
  },
  methods: {
    isvalidName() {
      let isValid = true;
      Object.keys(this.names).forEach((e) => {
        if (this.names[e].trim().length < 1) {
          isValid = false;
        }
      });

      return (this.vchange || !this.vchange) && isValid;
    },
    totalCount(list) {
      if (
        this.TicketsResult[this.selected] &&
        (this.TicketsResult[this.selected].ticketCode == "tk_dU7a7I9AiJYd48x" ||
          this.TicketsResult[this.selected].ticketCode == "tk_QMzy31e4IJKJvHp")
      ) {
        const tmp = Object.keys(list).length;
        return tmp > 0 ? [...Array(tmp).keys()] : [];
      }

      return [];
    },
    countChange() {
      const tmp = { ...this.names };
      this.names = {};

      if (this.tickcount > 1) {
        for (let i = 0; i < this.tickcount - 1; i++) {
          this.names["name" + i] = tmp["name" + i] || "";
        }
      }
    },
    invalidNum(n) {
      return (n || "").match(/\d/g) && (n || "").trim().length >= 10;
    },
    checkInvalidEmail(e) {
      return /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/.test(e);
    },
    section1Filter(list) {
      return list.filter((l) => this.section1.includes(l.ticketCode));
    },
    section2Filter(list) {
      return list.filter((l) => this.section2.includes(l.ticketCode));
    },

    donationSectionFilter(list) {
      return list.filter((l) => this.donationsSection.includes(l.ticketCode));
    },

    filteredMessages(obj) {
      if (obj && this.tkList && this.tkList[obj.ticketCode]) {
        return this.tkList[obj.ticketCode].messages;
      }

      return [];
    },
    closeModal() {
      this.showsuccess = false;
      this.$refs.confirmmodal.close();
    },
    openZelleModel: function () {
      this.$refs.zellemodal.open();
    },
    onChangeTicket: function (evt) {
      const countsList = [];

      (this.eventTicketResult || []).forEach((selectedTicket, idx) => {
        if (selectedTicket.ticketName === evt.target.value) {
          this.ticketForm.ticketId = selectedTicket.ticketCode;
          const at = this.eventTicketResult[idx].availableTickets;
          const apr = this.eventTicketResult[idx].allowedPerRequest;

          for (let k = 0; k < (at > apr ? apr : at); k++) {
            countsList.push(k + 1);
          }
        }
      });

      this.countsListShow = countsList;
    },
    validateFileType: function (ee) {
      const fileName = document.getElementById("fileName").value;
      const idxDot = fileName.lastIndexOf(".") + 1;
      const extFile = fileName.substr(idxDot, fileName.length).toLowerCase();
      if (extFile == "jpg" || extFile == "jpeg" || extFile == "png") {
        this.ticketForm.file = ee.target.files[0];
      }
    },
    openmodel: function (code) {
      this.validSection = true;
      this.fullname = "";
      this.email = "";
      this.efName = "";
      this.elName = "";
      this.eEmail = "";
      this.business_organization_name = "";
      this.emNumber = "";
      this.eTitle = "";
      this.eRefferedBy = "";
      this.efatherName = "";
      this.efEmail = "";
      this.ePhoneNumber = "";
      this.eOrgNumber = "";
      this.age = {};
      this.reserveFormAccept = false;
      this.nContactName = "";
      this.nEmailAddress = "";
      this.nStreetAddress = "";
      this.nState = "Alabama";
      this.nZip = "";
      this.nCity = "";
      this.nDistrict = "Dr. Sandra Thomas, Echo Joint Agreement";

      this.names = {};

      if (code == "RZ44zOQ66InQgZ") {
        this.age["age1"] = "";
      } else if (code == "tk_eVzbCBqzSuFPfd5") {
        this.age["age1"] = "";
        this.age["age2"] = "";
      } else if (code == "tk_g19EFw7kOMyfuL8") {
        this.age["age1"] = "";
        this.age["age2"] = "";
        this.age["age3"] = "";
      } else if (code == "tk_rFyeu7irKUhlI1U") {
        this.age["age1"] = "";
        this.age["age2"] = "";
        this.age["age3"] = "";
        this.age["age4"] = "";
      }
      let idx = 0;
      this.TicketsResult.forEach((t, ti) => {
        if (t.ticketCode === code) {
          idx = ti;
        }
      });
      this.redeemData = null;
      this.redeemPriceId = "";

      this.$refs.modal.open();
      this.selected = idx;
      this.tickcountlist = [];
      for (
        let k = 0;
        k <
        (this.TicketsResult[idx].availableTickets >
        this.TicketsResult[idx].allowedPerRequest
          ? this.TicketsResult[idx].allowedPerRequest
          : this.TicketsResult[idx].availableTickets);
        k++
      ) {
        this.tickcountlist.push(k + 1);
      }
    },
    paynow: function () {
      /**
       * This function here is for  pay methods
       */
      this.isSubmitted = true;
      const vm = this;
      let namelist = [this.fullname];
      Object.keys(this.names).forEach((e) => {
        namelist.push(this.names[e]);
      });
      const payloadToPush = {
        priceId:
          this.TicketsResult[vm.selected].discount &&
          this.redeemPriceId.trim().length
            ? this.redeemPriceId
            : this.priceIds[this.TicketsResult[vm.selected].ticketCode],
        eventId: this.TicketsResult[vm.selected].eventId,
        ticketId: this.TicketsResult[vm.selected].ticketCode,
        totalTickets: this.tickcount,
        userName: this.fullname,
        userEmail: this.email.toLowerCase(),
        additionalInfo: {
          type: "Name",
          value: namelist,
        },
      };

      if (
        [...this.section2].includes(
          this.TicketsResult[this.selected].ticketCode
        )
      ) {
        payloadToPush.extraInfo = {
          contact_name: this.nContactName,
          contact_email: this.nEmailAddress.toLowerCase(),
          address_street: this.nStreetAddress,
          address_city: this.nCity,
          address_state: this.nState,
          address_zip: this.nZip,
          address_district: this.nDistrict,
        };
      } else if (
        this.section1.includes(this.TicketsResult[this.selected].ticketCode)
      ) {
        payloadToPush.extraInfo = {
          contact_name: this.nContactName,
          contact_email: this.nEmailAddress.toLowerCase(),
          address_street: this.nStreetAddress,
          address_city: this.nCity,
          address_state: this.nState,
          address_zip: this.nZip,
          address_district: this.nDistrict,
        };
      } else if (
        this.donationsSection.includes(
          this.TicketsResult[this.selected].ticketCode
        )
      ) {
        payloadToPush.extraInfo = {
          contact_name: this.nContactName,
          contact_email: this.nEmailAddress.toLowerCase(),
          address_street: this.nStreetAddress,
          address_city: this.nCity,
          address_state: this.nState,
          address_zip: this.nZip,
          address_district: this.nDistrict,
        };
      }

      axios
        .post(
          "https://myle-19701.uc.r.appspot.com/api/" +
            ((this.TicketsResult[vm.selected].ticketType != "FREE" &&
              !this.redeemData) ||
            (this.redeemData && this.redeemData.price !== 0)
              ? "requested_tickets"
              : "requested_free_tickets"),
          payloadToPush
        )
        .then((re) => {
          if (re.data && re.data.Result && re.data.Result.sessionId) {
            vm.checkout.clientReferenceId = re.data.Result.sessionId;
            localStorage.setItem("refsId", vm.checkout.clientReferenceId);
            vm.$refs.checkoutRef.redirectToCheckout();
            setTimeout(() => {
              vm.isSubmitted = false;
            }, 60000);
            vm.reserveFormAccept = false;
          } else if (
            vm.TicketsResult[vm.selected].ticketType === "FREE" ||
            (this.redeemData && this.redeemData.price === 0)
          ) {
            vm.$toast.success(re.data.Message, { timeout: 4000 });
            vm.$refs.modal.close();
            vm.reserveFormAccept = false;
            vm.fullname = "";
            vm.email = "";
            vm.tickcount = 1;
            vm.isSubmitted = false;
            vm.fetchAllTickets();
            vm.reserveFormAccept = false;
          }

          vm.redeemData = null;
          vm.redeemPriceId = "";

          setTimeout(() => {
            vm.showsuccess = true;
            vm.$refs.confirmmodal.open();
          }, 1000);
        })
        .catch(() => {
          if (
            vm.TicketsResult[vm.selected].ticketType === "FREE" ||
            (this.redeemData && this.redeemData.price === 0)
          ) {
            vm.$toast.error("Ticket purchase failed", { timeout: 4000 });
            vm.$refs.modal.close();
            vm.reserveFormAccept = false;
            vm.fullname = "";
            vm.email = "";
            vm.tickcount = 1;
            vm.fetchAllTickets();
          }
          vm.isSubmitted = false;
          vm.reserveFormAccept = false;
          vm.redeemData = null;
          vm.redeemPriceId = "";

          this.showsuccess = false;
          setTimeout(() => {
            this.$refs.confirmmodal.open();
          }, 1000);
        });
    },
    redeem: function () {
      const vm = this;
      vm.isSubmitted = true;
      axios({
        method: "post",
        url: "https://myle-19701.uc.r.appspot.com/api/redeem_promo_code",
        data: {
          eventId: this.TicketsResult[this.selected].eventId,
          ticketId: this.TicketsResult[this.selected].ticketCode,
          promoCode: this.redeempromo,
        },
      })
        .then((re) => {
          if (re.data.Status === "Success") {
            vm.$toast.success(re.data.Message, { timeout: 4000 });
            vm.redeemPriceId = re.data.Result.discountedPriceId;
            vm.redeemData = re.data.Result;
          }
          vm.isSubmitted = false;
        })
        .catch(() => {
          vm.isSubmitted = false;
          vm.$toast.error("Promo code failed", { timeout: 4000 });
          vm.redeemPriceId = "";
          vm.redeemData = null;
        });
    },
    fetchAllTickets: function () {
      /**
       * This function here is used to fetch tickets
       */

      const vm = this;
      this.isSubmitted = true;
      this.eventTicketResult = [];
      this.TicketsResult = [];
      const payloadToPush = {
        eventId: "DDi3MDLuCebROg",
      };
      axios
        .post(
          "https://myle-19701.uc.r.appspot.com/api/fetchSponsoredEventTicketsWithSeating",
          payloadToPush
        )
        .then((ticketsResult) => {
          if (
            ticketsResult &&
            ticketsResult.data &&
            ticketsResult.data.Result
          ) {
            vm.TicketsResult = ticketsResult.data.Result;
          }

          vm.TicketsResult = vm.TicketsResult.filter(
            (rec) => vm.tkList[rec.ticketCode]
          );

          const objtmp = {
            tk_dU7a7I9AiJYd48x: null,
            tk_QMzy31e4IJKJvHp: null,

            tk_YOzzRx5mOgaeIu2: null,
            tk_xKPIrcyXYXEmOyC: null,
            tk_S6iXCYKjagcEBXN: null,
            tk_sCEleEPcdT4g8da: null,

            tk_9wu1JY7j3DxEjiZ: null,
            tk_xoeXu02Q0xiD9FX: null,
            tk_lixSrnyzRy8SLWI: null,
            tk_1f7iSJK5Nqp70tW: null,
            tk_CF2pAPuXAubu7Zq: null,
            tk_5Q67zL0t5U2x5mu: null,
            tk_6YLC1TkSt1QfxV7: null,
            tk_zTViqsmCU60HkvT: null,
            tk_WjtoOJep8CeNBfX: null,
          };

          vm.TicketsResult.forEach((tr) => {
            objtmp[tr.ticketCode] = tr; // tr.status="EXPIRED";
          });
          vm.TicketsResult = Object.values(objtmp);
          vm.eventTicketResult = vm.TicketsResult;
          vm.isSubmitted = false;
        })
        .catch(() => {
          vm.isSubmitted = false;
        });
    },
    payfornow: function () {
      /**
       * This function here is for Reservation form
       */

      // holding reference
      const vm = this;
      vm.isSubmitted = true;

      // creating form Data
      const bodyFormData = new FormData();
      bodyFormData.append("fullName", this.ticketForm.name);
      bodyFormData.append("email", this.ticketForm.email.toLowerCase());
      bodyFormData.append("phoneNumber", this.ticketForm.phone);
      bodyFormData.append("ticketId", this.ticketForm.ticketId);
      bodyFormData.append("quantity", this.ticketForm.ticketCount);
      bodyFormData.append("paymentType", this.ticketForm.method);
      bodyFormData.append("paymentReceipt", this.ticketForm.file);
      bodyFormData.append(
        "additionalInfo",
        JSON.stringify({
          type: "Note",
          value: "All sales are final - no refunds",
        })
      );

      // making api call
      axios({
        method: "post",
        url: "https://myle-19701.uc.r.appspot.com/api/ticket_order_confirmation",
        data: bodyFormData,
        headers: { "Content-Type": "multipart/form-data" },
      })
        .then((re) => {
          if (re.data.Status === "Success") {
            vm.$toast.success(re.data.Message, { timeout: 4000 });
          }
          // make get request again
          this.fetchAllTickets();
          vm.ticketForm = {
            name: "",
            email: "",
            phone: "",
            ticketCount: 0,
            ticketName: "",
            ticketId: "",
            method: "",
            accept: "",
            file: null,
          };
          document.getElementById("fileName").value = "";
          vm.isSubmitted = false;
        })
        .catch(() => {
          vm.isSubmitted = false;
          vm.ticketForm = {
            name: "",
            email: "",
            phone: "",
            ticketCount: 0,
            ticketName: "",
            ticketId: "",
            method: "",
            accept: "",
            file: null,
          };
          document.getElementById("fileName").value = "";
          vm.$toast.error("Ticket Confirmation failed", { timeout: 4000 });
        });
    },
  },
  created() {
    // if (this.$route.path == "/new-years-eve-2021-@-stadium") {
    //  this.$router.push("new-years-eve-2022-@-stadium");
    // }
  },
  mounted() {
    this.fetchAllTickets();
    const id = 1; // localStorage.getItem("refsId");
    if (
      id &&
      this.$route &&
      this.$route.query &&
      this.$route.query.checkout == "success"
    ) {
      this.$refs.confirmmodal.open();
      localStorage.removeItem("refsId");
    } else if (
      this.$route &&
      this.$route.query &&
      this.$route.query.checkout == "failed"
    ) {
      this.$refs.confirmmodal.open();
      localStorage.removeItem("refsId");
    }
  },
  computed: {
    invalidTicketEmail() {
      return /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/.test(
        this.ticketForm.email
      );
    },
    invalidTicketPhone() {
      return (
        (this.ticketForm.phone || "").match(/\d/g) &&
        (this.ticketForm.phone || "").trim().length >= 10
      );
    },
    invalidemail() {
      return /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/.test(this.email);
    },
    invalidphone() {
      return (
        (this.phone || "").match(/\d/g) &&
        (this.phone || "").trim().length >= 10
      );
    },
  },

  metaInfo: {
    title: "MYLE | Investing in our Future Scholarship Gala 2025",

    meta: [
      {
        name: "description",
        content: "MYLE | Investing in our Future Scholarship Gala 2025",
      },

      {
        name: "keywords",
        content: "MYLE | Investing in our Future Scholarship Gala 2025",
      },

      {
        name: "copyright",
        content: "MYLE",
      },

      {
        name: "url",
        content:
          "https://myle.com/investing-in-our-future-3rd-annual-scholarship-gala",
      },
    ],
  },
};
</script>

<style scoped>
#reserve {
  color: white;
}
#reserve:hover {
  color: #104233;
}
.background-overlay {
  background: #114436c4 !important;
  max-width: 100%;
  z-index: 9999 !important;
}
#banner {
  background-size: cover;
}
.innerimg {
  margin: auto;
  left: 0;
  right: 0;
  text-align: center;
  z-index: 1;
  max-width: 80%;
  height: auto;
  border: 1px double #d7c57d;
}

.bg-disable {
  background: pink;
  border: 1px solid pink;
  color: red !important;
  pointer-events: none;
}
.bolded {
  font-weight: bold;
}

.nu-modal.has-content.is-visible {
  max-width: 600px !important;
}
</style>
